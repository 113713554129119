import _ from "lodash";
import React, { useEffect, useState } from "react";
import { DragSource, DropTarget } from "react-dnd";
import { Csku, Grid, GridItem, } from "@commonsku/styles";

import DragItemTypes from "../../../DragItemTypes";
import { DebouncedInput } from "../../helpers";
import { DEFAULT_COLOR } from "../helpers";
import { getImgSrc, getPositionFromHero } from './helpers';

const mapImages = (images) => _.orderBy(
  images.map(v => ({ ...v, imageSrc: getImgSrc(v) })),
  [v => parseInt(v.key.replace('-image').replace('hero'))],
  ['asc']
);

function DraggableImagesGrid(props) {
  const {
    images,
    selectedImageId,
    onAddImage,
    onClickImage,
    onDeleteImage,
    onUpdateTitle,
    onClickAddImage,
    onReorderImages,
  } = props;
  const [state, setState] = useState(mapImages(images));

  useEffect(() => {
    setState(mapImages(images));
  }, [images]);

  const findIndex = (key) => {
    return state.map(v => v.key).indexOf(key);
  };

  const moveImage = (key, index) => {
    setState(prevState => {
      const imgs = prevState.slice();
      const origIndex = imgs.map(v => v.key).indexOf(key);
      imgs.splice(index, 0, imgs.splice(origIndex, 1)[0]);
      return imgs;
    });
  };

  const dropImage = (key) => {
    const value = state.find(v => v.key === key);
    if (!value) { return; }
    const newIdx = state.findIndex(v => v.key === key);
    const prevIdx = mapImages(images).findIndex(v => v.key === key);
    let idx = -1, idxKeyPos = -1;
    const toBeDeleted = [];
    const toBeAdded = [];
    if (newIdx === prevIdx) { return; }

    if (newIdx < prevIdx) { // movedLeft
      idx = newIdx;
    } else { // movedRight
      idx = prevIdx;
    }

    idxKeyPos = getPositionFromHero(state[idx+1]?.key);
    if (idxKeyPos === null || idxKeyPos === -1) {
      return;
    }
    state.slice(idx).forEach((v, i) => {
      toBeDeleted.push(`${v.key}-image`);
      toBeDeleted.push(`${v.key}-title`);
      toBeAdded.push({
        value: state[idx+i].imageSrc,
        key: `hero${i+idxKeyPos}-image`,
      });
      toBeAdded.push({
        value: state[idx+i].title,
        key: `hero${i+idxKeyPos}-title`,
      });
    });

    onReorderImages({
      delete: toBeDeleted,
      add: toBeAdded,
    });
  };

  return (
    <Grid gap={1} columns={3}>
      {state.map((image, idx) => (
        <Csku as={GridItem}
          key={`image-${image.file_id || image.key}`}
          colSpan={{ xs: 2, md: 1 }}
          style={{
            color: 'var(--color-neutrals-90)',
            fontFamily: 'var(--font-family-regular)',
            fontSize: 14,
          }}
          // onClick={() => onClickImage && onClickImage(image.key === selectedImageId ? '' : image.key)}
        >
          <div key={image.file_id || image.key} style={{
            textAlign: 'center',
            margin: '0.5rem',
            border: selectedImageId && selectedImageId === (image.file_id || image.key) ? `4px solid ${DEFAULT_COLOR}` : null,
            borderRadius: 8,
          }}>
            <DraggableCard
              item={image}
              index={idx}
              id={image.key}
              findIndex={findIndex}
              moveImage={moveImage}
              dropImage={dropImage}
              onChangeTitle={onUpdateTitle}
              onClickDelete={(key) => onDeleteImage(`${key}-title,${key}-image`)}
              canDelete={state.length > 1}
            />
          </div>
        </Csku>
      ))}
      <GridItem
        colSpan={{ xs: 2, md: 1 }}
        onClick={onClickAddImage}
        style={{
          color: 'var(--color-neutrals-90)',
          fontFamily: 'var(--font-family-regular)',
          fontSize: 14,
        }}
      >
        <div style={{
          textAlign: 'center',
          margin: '0.5rem',
          height: 165,
          border: '2px solid var(--color-primary1-40)',
          backgroundColor: 'var(--color-neutrals-20)',
          padding: 16,
          cursor: 'pointer'
        }}>
          <p style={{ textAlign: 'center', fontFamily: 'var(--font-family-bold)', color: 'var(--color-primary1-main)', fontSize: 35, paddingTop: 18, marginBottom: 0, lineHeight: '1.3' }}>+</p>
          <p style={{ textAlign: 'center', fontFamily: 'var(--font-family-bold)', color: 'var(--color-primary1-main)', fontSize: 24, marginBottom: 0 }}>Add Image</p>
        </div>
      </GridItem>
    </Grid>
  );
};

const imageDragSource = {
  beginDrag(props) {
    return {
      id: props.item.key,
      originalIndex: props.findIndex(props.item.key)
    };
  },

  endDrag(props, monitor) {
    const { id: droppedId, originalIndex } = monitor.getItem();
    const didDrop = monitor.didDrop();
    if (!didDrop) {
      props.moveImage(droppedId, originalIndex);
    } else {
      props.dropImage(droppedId);
    }
  }
};

const imageDropTarget = {
  hover(props, monitor) {
    const { id: draggedId } = monitor.getItem();
    const overId = props.item.key;

    if (draggedId !== overId) {
      const overIndex = props.findIndex(overId);
      props.moveImage(draggedId, overIndex);
    }
  }
};

const DraggableCard = DropTarget(DragItemTypes.IMAGE, imageDropTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))(DragSource(DragItemTypes.IMAGE, imageDragSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))(({
  item,
  isDragging,
  connectDragSource,
  connectDropTarget,
  onChangeTitle,
  onClickDelete,

  findIndex,
  moveImage,
  dropImage,
  canDelete=true,

  style={},
  ...props
}) => connectDragSource(
  connectDropTarget(
    <div>
      <Card
        item={item}
        style={style}
        onChangeTitle={onChangeTitle}
        onClickDelete={onClickDelete}
        canDelete={canDelete}
        {...props}
      />
    </div>
  )
)));

function Card({
  item,
  onChangeTitle,
  onClickDelete,
  style = {},
  canDelete = true,
  ...props
}) {
  return (
    <div style={{ position: 'relative', cursor: 'grab' }}>
      {canDelete && <div onClick={() => onClickDelete(item.key)} style={{
        cursor: 'pointer',
        position: 'absolute',
        backgroundColor: 'var(--color-errors-main)',
        top: -10,
        right: -10,
        height: 25,
        width: 25,
        borderRadius: '50%',
      }}>
        <div style={{ fontSize: 34, color: '#fff', marginTop: -12, }}>-</div>
      </div>}
      <div
        style={{
          height: 165,
          backgroundImage: `url('${item.imageSrc}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          ...style
        }}
      />
      <div style={{ paddingTop: 7 }}>
        <DebouncedInput
          type="text"
          placeholder="Title on image (Optional)"
          value={item.title}
          onChange={value => onChangeTitle(`${item.key}-title`, value)}
        />
      </div>
    </div>
  );
};

export default DraggableImagesGrid;
