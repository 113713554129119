import React, { useCallback, useMemo, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { Theme, XIcon, ArrowIcon, Button, LabeledCheckbox } from '@commonsku/styles';
import { BASE_ZINDEX } from '../../../popup-factory';
import { getImageSrc } from '../../../utils';
import { closePopup } from '../../../actions/popup';
import { createDeletePublicViewTemplateData, createUpdatePublicViewTemplateData } from '../../../actions/shop';
import { createUploadFile } from '../../../actions/file';
import withReducers from "../../../store/withReducers";
import webImageReducer from "../../../redux/webImage";
import FullScreenLoading from "../../helpers/FullScreenLoading";
import DraggableImagesGrid from './DraggableImagesGrid';
import UploadImagesTabs from "./UploadImagesTabs";
import { useContainerScroll } from "../../../hooks";
import { POPUP_TYPES } from '../Storefront/helpers';
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

const SelectStorefrontImage = (props) => {
  const {
    index,
    parent_id,
    parent_type,
    templateDataKey,
    isCarousel = false,
    isCarouselBanner = false,
    onClose,
    data={}
  } = props;
  const dispatch = useDispatch();
  const { entityId, entityType } = usePublicViewEntityContext();
  const {
    templateId,
    getCarouselImages,
    getCarouselImagesLastPos
  } = usePublicViewTemplateDataContext();

  const [ updateLoading, setUpdateLoading ] = useState(false);
  const carouselImages = useMemo(
    () => (isCarousel ? getCarouselImages() : []),
    [isCarousel, getCarouselImages]
  );
  const { applyToAllCategoriesCheckBox=false, applyToAllCategories=false, imageSrc='', popupType='' } = data;
  const recommendedSizeText = isCarouselBanner || [POPUP_TYPES.CATEGORY_BANNER, POPUP_TYPES.SELECT_BACKGROUND_IMAGE, POPUP_TYPES.SELECT_HELP_SCREEN_IMAGE].includes(popupType) ? '1456 X 1007 px' : '1300 X 225 px';
  const showUnsplash = templateDataKey !== 'header-title';
  const [showCarousel, setShowCarousel] = useState(isCarousel && carouselImages.length > 0);
  const ref = useRef(null);
  const { elemRect } = useContainerScroll(ref, [
    parent_id,
    parent_type,
    templateDataKey,
    isCarousel,
    showCarousel,
    showUnsplash,
  ]);
  const popupBodyHeight = (elemRect.height || 250) - (16 + 80 + 4 + (!showCarousel && showUnsplash ? 65 : 0) + (showCarousel ? 100 : 0));
  const [applyBannerToAllCategories, setApplyBannerToAllCategories] = useState(!!applyToAllCategories);

  const onClosePopup = useCallback((e) => {
    dispatch(closePopup());
    onClose && onClose();
  }, [dispatch, onClose]);

  const onChangeBackground = useCallback(async (image_src) => {
    setUpdateLoading(true);
    let dataKey = templateDataKey;
    if (isCarousel) {
      const heroImagesLen = getCarouselImagesLastPos();
      dataKey = `hero${heroImagesLen+1}-image`;
    }
    dispatch(createUpdatePublicViewTemplateData(
      entityId, entityType, templateId,
      dataKey,
      image_src
    )).then(() => {
      if (dataKey === 'header-title') {
        return dispatch(createUpdatePublicViewTemplateData(
          entityId, entityType, templateId,
          dataKey + '-type',
          'image'
        ));
      }

      if (applyBannerToAllCategories) {
        return dispatch(createUpdatePublicViewTemplateData(
          entityId, entityType, templateId,
          "CATEGORY-ALL-IMAGE-BANNER",
          image_src
        ));
      }
    }).then(() => !isCarousel && onClosePopup());
    setUpdateLoading(false);
    isCarousel && setShowCarousel(true);
  }, [
    dispatch,
    entityId,
    entityType,
    templateId,
    templateDataKey,
    isCarousel,
    onClosePopup,
    getCarouselImagesLastPos,
    applyBannerToAllCategories,
  ]);

  const onUploadFile = useCallback(async (files) => {
    setUpdateLoading(true);
    await dispatch(createUploadFile(entityId, entityType, files[0])).then(
      action => onChangeBackground(getImageSrc(action.payload.data, 'original'))
    );
    setUpdateLoading(false);
  }, [dispatch, onChangeBackground, entityId, entityType]);

  const handleCheckApplyToAllCategories = useCallback(
    (e) => {
      const isChecked = e.target.checked;
      setApplyBannerToAllCategories(isChecked);
      dispatch(
        createUpdatePublicViewTemplateData(
          entityId, entityType, templateId,
          "APPLY-BANNER-TO-ALL-CATEGORIES",
          isChecked + 0
        )
      );
      if (!!imageSrc && isChecked) {
        dispatch(
          createUpdatePublicViewTemplateData(
            entityId, entityType, templateId,
            "CATEGORY-ALL-IMAGE-BANNER",
            imageSrc
          )
        );
      }
    },
    [dispatch, imageSrc, entityId, entityType, templateId]
  );

  if (entityId !== parent_id) {
    return null;
  }

  return (
    <Theme>
      <FullScreenLoading loading={updateLoading} message="Updating...Please Wait" zIndex={index+10} />
      <div ref={ref} className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, maxWidth: 980, }} role="dialog">
        {!showCarousel && <XIcon style={{position: 'absolute', right: '1rem', cursor: 'pointer'}} onClick={onClosePopup}/>}
        <div className="row" style={{height: '100%'}}>
          <div className="small-12 columns" style={{ paddingBottom: 14, paddingLeft: 0, paddingRight: 0, paddingTop: 0, }}>
            <p style={{
              textAlign: 'left',
              fontFamily: 'var(--font-family-bold)',
              color: 'var(--color-neutrals-90)',
              fontSize: 24,
              marginBottom: 0,
              lineHeight: '1.3'
            }}>
              {isCarousel && !showCarousel ? <ArrowIcon
                direction="left"
                style={{ verticalAlign: 'middle', cursor: 'pointer', marginRight: 14 }}
                onClick={() => setShowCarousel(true)}
              /> : null}
              <span>
                {showCarousel
                  ? 'Your Carousel'
                  : `Upload your ${templateDataKey === 'header-title' ? 'logo' : 'images'} here`}
              </span>
            </p>
            <p style={{
              textAlign: 'left',
              fontFamily: 'var(--font-family-regular)',
              color: 'var(--color-neutrals-90)',
              fontSize: 16,
              marginBottom: 0,
              paddingTop: 8,
              lineHeight: '1.3'
            }}>
              {showCarousel ?
                'Drag and drop your images to rearrange.' :
              templateDataKey === 'header-title' ?
                'Preferred size is 400px X 400px and in .png format.' :
                `Upload an image below or select it from the Unsplash tab. Preferred size is ${recommendedSizeText}.`}
            </p>
          </div>
          <div className="small-12 columns" style={{height: popupBodyHeight, paddingLeft: 0, paddingRight: 0, paddingBottom: 0, position: 'relative' }}>
            {applyToAllCategoriesCheckBox &&
              <div style={{position: 'absolute', right: 0, top: '32px'}}>
                <LabeledCheckbox checked={applyBannerToAllCategories}
                                 label='Apply to all category pages'
                                 onChange={handleCheckApplyToAllCategories}
                />
              </div>}
            <UploadImagesTabs
              recommendedSize={recommendedSizeText}
              height={popupBodyHeight-12}
              imgSearchBoxHeight={popupBodyHeight-130}
              dropzoneHeight={popupBodyHeight-30}
              showCarousel={showCarousel}
              showUnsplash={showUnsplash}
              CarouselImagesGrid={(
                <DraggableImagesGrid
                  images={carouselImages}
                  selectedImageId={templateDataKey}
                  onClickAddImage={() => setShowCarousel(false)}
                  onReorderImages={(data) => {
                    return dispatch(createUpdatePublicViewTemplateData(
                      entityId, entityType, templateId,
                      'reorder-carousel-images',
                      data
                    ));
                  }}
                  onAddImage={(field, value) => {
                    return dispatch(createUpdatePublicViewTemplateData(
                      entityId, entityType, templateId,
                      field,
                      value
                    ));
                  }}
                  onDeleteImage={(field) => {
                    if (!field || field.length === 0) { return; }
                    if (typeof field === 'object' && Array.isArray(field)) {
                      field = field.join(',');
                    }
                    if (field.trim().length === 0) { return; }
                    return dispatch(createDeletePublicViewTemplateData(
                      entityId, entityType, templateId,
                      field,
                    ));
                  }}
                  onUpdateTitle={(field, value) => {
                    dispatch(createUpdatePublicViewTemplateData(
                      entityId, entityType, templateId,
                      field,
                      value
                    ));
                  }}
                />
              )}
              setShowCarousel={setShowCarousel}
              onUploadFile={onUploadFile}
              onChangeBackground={onChangeBackground}
            />
          </div>
          {showCarousel && <div style={{
            textAlign: 'center',
            height: 75,
            bottom: 0,
            position: 'fixed',
            width: '98%',
            background: '#fff',
            padding: '8px 0px',
          }}>
            <Button
              onClick={onClosePopup}
              variant="primary"
            >Done</Button>
          </div>}
        </div>
      </div>
    </Theme>
  );
};

export default withReducers(SelectStorefrontImage, { webImage: webImageReducer });
