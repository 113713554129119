import { getImageSrc } from "../../../utils";

export const getImgSrc = (image) => {
  if (image.imageSrc) {
    return image.imageSrc;
  } else if (image.image) {
    if (typeof image.image === 'object') {
      return getImageSrc(image.image, 'original');
    }
    return image.image;
  } else if (image.original) {
    if (typeof image.original === 'object') {
      return getImageSrc(image.original, 'original');
    }
    return image.original;
  }

  return getImageSrc(image, 'original');
};

export const getPositionFromHero = (key) => {
  const matches = key.match(/^hero([0-9]+)(-)?(title|image)?$/);
  if (!matches || matches.length < 2) {
    return null;
  }
  return parseInt(matches[1], 10);
};
